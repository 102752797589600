/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.disable-date {
    background-image: repeating-linear-gradient(135deg, rgba(244, 67, 54, 0.1), rgba(244, 67, 54, 0.1) 4px, transparent 4px, transparent 9px);
    color: #9B6467;
}

.lockedPeriod {
    background-color: darkgray !important;
    opacity: .45 !important;
}

@media (min-width: 1200px) {
    .container {
        width: 1920px !important;
    }
}

.container.wide {
    width: 1920px;
}

.Modal {
    position: absolute;
    top: 25%;
    left: 25%;
    right: 25%;
    bottom: 25%;
    background-color: white;
    border: solid;
    border-width: 1px;
    padding: 5px;
}

.Modal.Large {
    position: absolute;
    top: 15%;
    left: 15%;
    right: 15%;
    bottom: 15%;
    background-color: white;
    border: solid;
    border-width: 1px;
    padding: 5px;
}

.selected-row {
    background-color: #009688 !important;
}

.whateverClassYouWish {
    width: 250px;
    height: 250px;
}

.chart-inner-text {
    position: absolute;
    top: 0;
    right: 0;
    left: -620px;
    bottom: 0;
    padding-top: 8%;
    text-align: center;
    font-size: 13px;
}

.chart-inner-value {
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5c596d;
    font-size: 15px;
    padding-bottom: 5px;
}

.donutchart-track {
    fill: transparent;
    stroke: #DAE2E5;
    stroke-width: 26;
}

.donutchart-indicator {
    fill: transparent;
    stroke: #009688;
    stroke-width: 26;
    stroke-dasharray: 0 10000;
    transition: stroke-dasharray .3s ease;
}

.donutchart {
    margin: 0 auto;
    border-radius: 50%;
    display: block;
}


.donutchart-text {
    font-family: 'Roboto';
    fill: #607580;
}

.donutchart-text-val {
    font-size: 22px;
}

.donutchart-text-percent {
    font-size: 14px;
}

.donutchart-text-label {
    font-size: 9px;
}

.dx-gantt .dx-gantt-tm.current-time {
    border-left: 1px solid red;
}

.transportLeaderRow {
    background-color: #60a69f;
    color: white !important;
}

.cancelledRow {
    background-color: #b3b3b3;
    color: white !important;
}

.table, th, td {
    border: 1px solid #333333;
    vertical-align: top;
    padding: 5px;
}

.spacer {
    height: 20px;
}

.keyField {
    background-color: #FFFF00;
    font-weight: bold;
}

.dx-field-item {
    margin-top: 2px;
}

.dx-field-item .dx-last-row {
    padding-bottom: 10px;
}